<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >新增小组</Divider>
    <Form
      ref="workGroupModel"
      :model="workGroupModel"
      :rules="ruleValidate"
    >
      <FormItem
        label="小组名称"
        prop="name"
      >
        <Input
          type="text"
          size="small"
          v-model.trim="workGroupModel.name"
        />
      </FormItem>
      <!-- <FormItem
        label="小组类型"
        prop="type"
      >
        <Select v-model="workGroupModel.type">
          <Option
            v-for="(key,value) in workGroupTypeList"
            :key="value"
            :value="value"
          >{{key}}</Option>
        </Select>
      </FormItem> -->
      <FormItem class="text-right">
        <Button
          type="success"
          size="small"
          :loading="btnLoading"
          @click.stop="hanldeAddWorkGroup('workGroupModel')"
        >提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { addWorkGroup } from '@/api/os/workgroup'
export default {
  data () {
    return {
      btnLoading: false,
      // workGroupTypeList: [],
      workGroupModel: {
        name: '',
        companyId: this.$store.getters.token.userInfo.companyId,
        type: null
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
        // type: [
        //   { required: true, message: '选择小组类型', trigger: 'change' }
        // ],
        // companyId: [
        //   { required: true, type: 'number', message: '选择运维公司', trigger: 'change' }
        // ]
      }
    }
  },
  created () {
    // this.getWorkGroupTypes()
  },
  methods: {
    // getWorkGroupTypes () {
    //   getWorkGroupType().then(res => {
    //     this.workGroupTypeList = res
    //   })
    // },
    hanldeAddWorkGroup (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          addWorkGroup(this.workGroupModel).then(res => {
            this.btnLoading = false
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.$store.dispatch('getWorkGroupData')
            }
          })
        }
      })
    }
  }
}
</script>
